@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
@import url("https://fonts.cdnfonts.com/css/helvetica-neue-55?styles=30125,16016,16018,16008,16022,16009,15981,15985,15987,16012,15980,15984,15989,16013,15992,15994,15995,30127,30128,30129,15999,30123,30130,30135,15997,15998");
@import "./utils.scss";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -ms-overflow-style: none;
  /* IE and Edge */
  // scrollbar-width: none;
  // scroll-behavior: auto;
}

body {
  background: var(--color-dark);
  font-family: var(--default-font);
  color: var(--color-light);
  font-size: 16px;
  line-height: 1.3;
  transition: var(--transition-ease);
  height: 100vh;
  overflow: hidden;
}

h1,
h2,
h3 {
  margin: 0;
  font-weight: var(--font-medium);
}

h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}

header {
  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0 0 auto 0;
    width: 100%;
    padding: 2.5rem 0 0 0;
    z-index: 1000;
    color: var(--color-light);
    mix-blend-mode: difference;
    img.logo {
      height: 25px;
      max-width: fit-content;
      transition: var(--transition-ease);
      &:hover {
        filter: brightness(0.5);
        transition: var(--transition-ease);
      }
    }
  }
}

canvas {
  position: relative;
  z-index: 900;
}

.container {
  height: 100%;
  width: 100%;
  background-color: var(--color-dark);
}

.overlay {
  opacity: 0;
  display: block;
  position: fixed;
  transform: translateX(100%);
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark);
  z-index: 1010;

  .modal__dialog {
    display: flex;
    flex-direction: column;
    position: relative;
    color: var(--color-light);
    padding: var(--space-regualar);
    margin: var(--space-regualar);
    height: 100%;
    width: 100%;
    .modal__header {
      position: fixed;
      inset: 0;
      display: flex;
      justify-content: flex-end;
      height: 140px;
      z-index: 1020;

      .close__btn {
        position: relative;
        inset: 50px 50px auto auto;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
          transition: var(--transition-ease);
        }
      }

      .close__btn::before,
      .close__btn::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: var(--color-light);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1020;
      }

      .close__btn::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      .close__btn::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .modal__body {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 100px 50px;

      .modal__title {
        height: 70px;
        h1 {
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: var(--font-regular);
        }
      }
      .modal__content {
        display: flex;
        position: relative;
        width: 100%;
        .modal__image {
          margin: 70px;
          img {
            height: 500px;
            max-width: 100%;
          }
        }
        .modal__text {
          color: var(--color-grey);
          margin: 70px;
          max-width: 500px;
        }
      }
    }

    .modal__footer {
      height: 140px;
    }
  }
}
