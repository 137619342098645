@import "https://fonts.googleapis.com/css2?family=Staatliches&display=swap";
@import "https://fonts.cdnfonts.com/css/helvetica-neue-55?styles=30125,16016,16018,16008,16022,16009,15981,15985,15987,16012,15980,15984,15989,16013,15992,15994,15995,30127,30128,30129,15999,30123,30130,30135,15997,15998";
:root {
  --color-dark: #151516;
  --color-dark-2: #202226;
  --color-light: #f8f8f8;
  --color-sand: #d8d6cb;
  --color-grey: #636363;
  --default-font: "Helvetica Neue", sans-serif;
  --font-light: 300;
  --font-medium: 400;
  --font-regular: 500;
  --font-bold: 600;
  --font-xsmall: .7rem;
  --font-small: .8rem;
  --font-normal: 1rem;
  --font-slarge: 1.2rem;
  --font-large: 1.8rem;
  --font-xlarge: 2.3rem;
  --transition-ease: all .5s ease;
  --space-small: 20px;
  --space-regular: 60px;
  --space-medium: 100px;
  --space-large: 200px;
  --container-small: 300px;
  --container-medium: 640px;
  --container-large: 1024px;
  --container-xlarge: 1200px;
  --container-xxlarge: 1400px;
  --container-xxxlarge: 1600px;
  --container-full: 100%;
  --element-small: 20px;
  --element-regular: 40px;
  --element-medium: 80px;
  --element-large: 100px;
  --element-xlarge: 200px;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -ms-overflow-style: none;
}

body {
  background: var(--color-dark);
  font-family: var(--default-font);
  color: var(--color-light);
  transition: var(--transition-ease);
  height: 100vh;
  font-size: 16px;
  line-height: 1.3;
  overflow: hidden;
}

h1, h2, h3 {
  font-weight: var(--font-medium);
  margin: 0;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

header .navbar {
  z-index: 1000;
  width: 100%;
  color: var(--color-light);
  mix-blend-mode: difference;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0 0;
  display: flex;
  position: fixed;
  inset: 0 0 auto;
}

header .navbar img.logo {
  max-width: fit-content;
  height: 25px;
  transition: var(--transition-ease);
}

header .navbar img.logo:hover {
  filter: brightness(.5);
  transition: var(--transition-ease);
}

canvas {
  z-index: 900;
  position: relative;
}

.container {
  background-color: var(--color-dark);
  width: 100%;
  height: 100%;
}

.overlay {
  opacity: 0;
  background-color: var(--color-dark);
  z-index: 1010;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  inset: 0;
  transform: translateX(100%);
}

.overlay .modal__dialog {
  color: var(--color-light);
  padding: var(--space-regualar);
  margin: var(--space-regualar);
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.overlay .modal__dialog .modal__header {
  z-index: 1020;
  justify-content: flex-end;
  height: 140px;
  display: flex;
  position: fixed;
  inset: 0;
}

.overlay .modal__dialog .modal__header .close__btn {
  cursor: pointer;
  background-color: #0000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
  inset: 50px 50px auto auto;
}

.overlay .modal__dialog .modal__header .close__btn:hover {
  opacity: .5;
  transition: var(--transition-ease);
}

.overlay .modal__dialog .modal__header .close__btn:before, .overlay .modal__dialog .modal__header .close__btn:after {
  content: "";
  background-color: var(--color-light);
  z-index: 1020;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay .modal__dialog .modal__header .close__btn:before {
  transform: translate(-50%, -50%)rotate(45deg);
}

.overlay .modal__dialog .modal__header .close__btn:after {
  transform: translate(-50%, -50%)rotate(-45deg);
}

.overlay .modal__dialog .modal__body {
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 100px 50px;
  display: flex;
  position: relative;
}

.overlay .modal__dialog .modal__body .modal__title {
  height: 70px;
}

.overlay .modal__dialog .modal__body .modal__title h1 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: var(--font-regular);
}

.overlay .modal__dialog .modal__body .modal__content {
  width: 100%;
  display: flex;
  position: relative;
}

.overlay .modal__dialog .modal__body .modal__content .modal__image {
  margin: 70px;
}

.overlay .modal__dialog .modal__body .modal__content .modal__image img {
  max-width: 100%;
  height: 500px;
}

.overlay .modal__dialog .modal__body .modal__content .modal__text {
  color: var(--color-grey);
  max-width: 500px;
  margin: 70px;
}

.overlay .modal__dialog .modal__footer {
  height: 140px;
}
/*# sourceMappingURL=index.aadef7ba.css.map */
