:root {
  --color-dark: #151516;
  --color-dark-2: #202226;
  --color-light: #f8f8f8;
  --color-sand: #d8d6cb;
  --color-grey: #636363;
  --default-font: "Helvetica Neue", sans-serif;
  --font-light: 300;
  --font-medium: 400;
  --font-regular: 500;
  --font-bold: 600;
  --font-xsmall: 0.7rem;
  --font-small: 0.8rem;
  --font-normal: 1rem;
  --font-slarge: 1.2rem;
  --font-large: 1.8rem;
  --font-xlarge: 2.3rem;
  --transition-ease: all 0.5s ease;
  --space-small: 20px;
  --space-regular: 60px;
  --space-medium: 100px;
  --space-large: 200px;
  --container-small: 300px;
  --container-medium: 640px;
  --container-large: 1024px;
  --container-xlarge: 1200px;
  --container-xxlarge: 1400px;
  --container-xxxlarge: 1600px;
  --container-full: 100%;
  --element-small: 20px;
  --element-regular: 40px;
  --element-medium: 80px;
  --element-large: 100px;
  --element-xlarge: 200px;
}
